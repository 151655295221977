import React, { useState } from 'react'
import logo from '../../assets/logowhite.png'
import './Footer.css'
import fb from '../../assets/fblogo.png'
import ig from '../../assets/iglogo.png'
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom'

const Footer = () => {

    const [callus, setcallus] = useState(false)
    const phoneNumber = '9155018111';  // Your WhatsApp number

    return (
        <div className='Footer'>
            <div className='footerContent'>

                <div>
                    <div className='footerHeading'>
                        <img src={logo} className='footerLogo' />
                    </div>
                    <div className='footerAddress'>
                        <div className='footerBold '>INDIA</div>
                        <div>3rd floor, The Emerald,<br /> Patliputra Kurji Rd, <br />opposite Bharat Petroleum Pump, <br />Patliputra Industrial Area, Patliputra Colony, <br />Patna, Bihar- 800013</div>
                    </div>
                </div>

                <div>
                    <div className='footerHeading footerBold'>QUICK LINKS</div>
                    <div className='footerList'>
                        <a href="https://www.facebook.com/aadmiwala" target='_blank'>
                            <div className='footerSocialMedia'> <img src={fb} /> Facebook
                            </div>
                        </a>

                        <a href="https://www.instagram.com/covendx_" target='_blank'>
                            <div className='footerSocialMedia'><img src={ig} /> Instagram</div>
                        </a>

                        <div>
                            <Link to={`/blogs`}>Blogs</Link>
                        </div>
                        <div>
                            <Link to={`/about`}>About Us</Link>
                        </div>
                    </div>
                </div>



                {/* <div>
                <div className='footerHeading footerBold'>
                    FAQ's
                </div>
                <div className='footerList'>
                    <div>
                        Recruitment Agency
                    </div>
                    <div>
                        Client Recruiters
                    </div>
                    <div>
                        
                     </div>
                     <div>
                        
                     </div>
                </div>
            </div> */}

                <div>
                    <div className='footerHeading footerBold'>
                        LET'S TALK
                    </div>
                    <div className='footerList'>
                        <div>
                            <a href='mailto:admin@covendx.com' target='_blank'>
                                admin@covendx.com
                            </a>
                        </div>

                        <div 
  className={callus ? 'footerOrange footerbtnwidth' : 'footerOrange'}
  onClick={() => {
    if (!callus) {
      // Open WhatsApp chat
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    }
    setcallus(!callus); // Toggle the state after the condition
  }}
>
  {callus ? 
    <>9155018111 <EastIcon /></> 
    : 
    <>Contact Us <EastIcon /></>
  }
</div>

                        <div>

                        </div>
                        <div>

                        </div>
                    </div>
                </div>

            </div>


            <div className='footerTerms'>
                <div>Terms of Service</div>
                <div>Terms & Conditions</div>
                <div>Privacy Policy</div>
            </div>

            <div className='footerCopyright'>
                ©2024 Covendx, All Rights Reserved.
                <br/> (A Division of AXISVENDX SERVICES PRIVATE LIMITED)
            </div>
        </div>
    )
}

export default Footer